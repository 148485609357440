import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import UilEllipsisV from '@iconscout/react-unicons/icons/uil-ellipsis-v';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { ReactComponent as Activities } from '../static/assets/activities.svg';
import { getItem } from '../utility/localStorageControl';
function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getMenuItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const role = getItem('role');

  const path = '/admin';

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath?.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const adminItems = [

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/activation`}>
        {t('activation')}
      </NavLink>,
      'activation',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/activation`}>
          <FontAwesome className="flex -ml-5" name="table" />
        </NavLink>
      ),
    ),

    getMenuItem(t('Tests'), 'Tests', !topMenu && <FontAwesome className="flex w-4" name="puzzle-piece" />, [
      getMenuItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/forms`}>
          {t('Tests')}
        </NavLink>,
        'tests',
        null,
      ),
    ]),

    getMenuItem(t('Activities'), 'Activities', !topMenu && <Activities />, [
      getMenuItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/activities/webinars`}>
          {t('Webinars')}
        </NavLink>,
        'webinars',
        null,
      ),
      getMenuItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/activities/add-webinar`}>
          {t('Add Webinar')}
        </NavLink>,
        'add-webinars',
        null,
      ),
      getMenuItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/activities/courses-workshops`}>
          {t('Courses/Workshops')}
        </NavLink>,
        'courses-workshops',
        null,
      ),
    ]),

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/notifications`}>
        {t('notifications')}
      </NavLink>,
      'notifications',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/notifications`}>
          <FontAwesome className="flex -ml-5" name="bell" />
        </NavLink>
      ),
    ),

    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/joining_list`}>
        {t('Webinar Joinees')}
      </NavLink>,
      'Webinar Joinees',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/joining_list`}>
          <FontAwesome className="flex -ml-5" name="list" />
        </NavLink>
      ),
    ),
  ];

  const salesItems = [
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}`}>
        {t('OverView')}
      </NavLink>,
      'overview',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}`}>
          <FontAwesome className="flex -ml-5" name="table" />
        </NavLink>
      ),
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/salesadmin`}>
        {t('Sales')}
      </NavLink>,
      'sales',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/pages/salesadmin`}>
          <FontAwesome className="flex -ml-5" name="table" />
        </NavLink>
      ),
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/pages/sales_institutes`}>
        {t('Institutes')}
      </NavLink>,
      'Institutes',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}`}>
          <FontAwesome className="flex -ml-5" name="table" />
        </NavLink>
      ),
    ),
  ];

  const clientItems = [
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/activities/webinars`}>
        {t('Webinars')}
      </NavLink>,
      'webinars',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/activities/webinars`}>
          <FontAwesome className="flex -ml-5" name="table" />
        </NavLink>
      ),
    ),
    getMenuItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/activities/reels`}>
        {t('Career Reels')}
      </NavLink>,
      'Career Reels',
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/activities/reels`}>
          <Activities />
        </NavLink>
      ),
    ),
  ]

  let items = 'ROLE_ADMIN';

  switch (role) {
    case 'ROLE_ADMIN':
      items = adminItems;
      break;
    case 'ROLE_SALES':
      items = salesItems;
      break;
    case 'ROLE_CLIENT':
      items = clientItems;
      break;
    default:
      items = salesItems;
      break;
  }

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
