import axios from 'axios';
import { getItem } from '../../utility/localStorageControl';
import { alertModal } from '../../components/modals/antd-modals';
import Cookies from 'js-cookie';

const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/api`;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem('access_token')}`, // should try to follow standard method
    'Content-Type': 'application/json',
    token: getItem('access_token'),
  },
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = {
    ...headers,
    Authorization: `Bearer ${getItem('access_token')}`,
    token: `${getItem('access_token')}`,
  };

  return requestConfig;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;

    if (response) {
      console.log('captured response : ', response);
      if (response.status === 500) {
      } else if (response.status === 401) {
        console.log('401 hit : ', response);
        alertModal.warning({
          title: 'Session expired',
          content: 'Please login again',
          onOk() {
            Cookies.remove('logedIn');
            Cookies.remove('access_token');
            window.location.href = '/';
          },
        });
      } else {
        console.log('error : ', response);
        alertModal.error({
          title: 'Something went wrong',
          content: JSON.stringify(response.data),
        });
        return response;
      }
    }
    return Promise.reject(error);
  },
);

export { DataService };
