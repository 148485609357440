import React, { useLayoutEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
  UilEnvelope,
  UilChat,
  UilShoppingCart,
  Uil500px,
  UilBagAlt,
  UilCalendarAlt,
  UilUsersAlt,
  UilAt,
  UilClipboardAlt,
  UilCheckSquare,
  UilExchange,
  UilFile,
  UilHeadphones,
} from '@iconscout/react-unicons';
import { TopMenuStyle } from './Style';

function TopMenu() {
  const path = '/admin';

  useLayoutEffect(() => {
    const active = document.querySelector('.hexadash-top-menu a.active');
    const activeDefault = () => {
      const megaMenu = active.closest('.megaMenu-wrapper');
      const hasSubMenuLeft = active.closest('.has-subMenu-left');
      if (!megaMenu) {
        active.closest('ul').previousSibling.classList.add('active');
        if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
      } else {
        active.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
      }
    };
    window.addEventListener('load', active && activeDefault);
    return () => window.removeEventListener('load', activeDefault);
  }, []);

  const addParentActive = (event) => {
    document.querySelectorAll('.parent').forEach((element) => {
      element.classList.remove('active');
    });

    const hasSubMenuLeft = event.currentTarget.closest('.has-subMenu-left');
    const megaMenu = event.currentTarget.closest('.megaMenu-wrapper');
    if (!megaMenu) {
      event.currentTarget.closest('ul').previousSibling.classList.add('active');
      if (hasSubMenuLeft) hasSubMenuLeft.closest('ul').previousSibling.classList.add('active');
    } else {
      event.currentTarget.closest('.megaMenu-wrapper').previousSibling.classList.add('active');
    }
  };
  return (
    <TopMenuStyle>
      <div className="hexadash-top-menu ltr:pl-[20px] rtl:pr-[20px] xl:ltr:pl-[10px] xl:rtl:pr-[10px]">
        <ul>

          <li className="has-subMenu">
            <Link to="#" className="parent">
              Apps
            </Link>
            <ul className="subMenu">
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <UilEnvelope />
                  Email
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/email/inbox`}>
                      Inbox
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/email/single/1585118055048`}>
                      Read Email
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink onClick={addParentActive} to={`${path}/main/chat/private/rofiq@gmail.com`}>
                  <UilChat />
                  Chat
                </NavLink>
              </li>
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <UilShoppingCart />
                  eComerce
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/products/grid`}>
                      Products
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/productDetails/1`}>
                      Products Details
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/add-product`}>
                      Product Add
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/edit-product`}>
                      Product Edit
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/cart`}>
                      Cart
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/orders`}>
                      Orders
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/sellers`}>
                      Sellers
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/ecommerce/Invoice`}>
                      Invoices
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <Uil500px />
                  Social App
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/profile/myProfile/overview`}>
                      My Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/profile/myProfile/timeline`}>
                      Timeline
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/profile/myProfile/activity`}>
                      Activity
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <UilBagAlt />
                  Project
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/project/view/grid`}>
                      Project Grid
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/project/view/list`}>
                      Project List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/project/create`}>
                      Create Project
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/project/projectDetails/1`}>
                      Project Details
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li>
                <NavLink onClick={addParentActive} to={`${path}/app/calendar/month`}>
                  <UilCalendarAlt />
                  Calendar
                </NavLink>
              </li>
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <UilUsersAlt />
                  Users
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/users/team`}>
                      Team
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/users/grid`}>
                      Users Grid
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/users/list`}>
                      Users List
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/users/grid-style`}>
                      Users Grid Style
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/users/grid-group`}>
                      Users Group
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/users/add-user/info`}>
                      Add User
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/users/dataTable`}>
                      User Table
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <UilAt />
                  Contact
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/contact/addNew`}>
                      Contact Create
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/contact/grid`}>
                      Contact Grid
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/contact/list`}>
                      Contact List
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink onClick={addParentActive} to={`${path}/app/note/all`}>
                  <UilClipboardAlt />
                  Note
                </NavLink>
              </li>
              <li>
                <NavLink onClick={addParentActive} to={`${path}/app/to-do/`}>
                  <UilCheckSquare />
                  To Do
                </NavLink>
              </li>
              {/* <li>
                <NavLink onClick={addParentActive} to={`${path}/app/kanban`}>
                  <UilExpandArrowsAlt />
                  Kanban Board
                </NavLink>
              </li> */}
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <UilExchange />
                  Import Export
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/importExport/import`}>
                      Import
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/importExport/export`}>
                      Export
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink onClick={addParentActive} to={`${path}/app/task/all`}>
                  <UilFile />
                  Task
                </NavLink>
              </li>
              <li>
                <NavLink onClick={addParentActive} to={`${path}/app/support/tickets`}>
                  <UilHeadphones />
                  Support
                </NavLink>
              </li>
              <li className="has-subMenu-left">
                <Link to="#" className="parent">
                  <UilAt />
                  Learning App
                </Link>
                <ul className="subMenu">
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/importExport/import`}>
                      Course
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/importExport/export`}>
                      Course Single
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="mega-item has-subMenu">
            <Link to="#" className="parent">
              Components
            </Link>
            <ul className="megaMenu-wrapper megaMenu-wide">
              <li>
                <span className="mega-title">Components</span>
                <ul>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/alerts`}>
                      Alert
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/auto-complete`}>
                      AutoComplete
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/avatar`}>
                      Avatar
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/badge`}>
                      Badge
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/breadcrumb`}>
                      Breadcrumb
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/button`}>
                      Buttons
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/calendar`}>
                      Calendar
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/cards`}>
                      Card
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/carousel`}>
                      Carousel
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/cascader`}>
                      Cascader
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/checkbox`}>
                      Checkbox
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={addParentActive} to={`${path}/components/collapse`}>
                      Collapse
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </TopMenuStyle>
  );
}

export default TopMenu;
