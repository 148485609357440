import { Form, InputNumber, Modal, Select, Skeleton, Table, notification } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { GlobalUtilityStyle, TableDefaultStyle } from '../../styled';

import { Link } from 'react-router-dom';
import { DataService } from '../../../config/dataService/dataService';

const SalesOverviewAdmin = React.memo(({ salesData, targetsData, email, refresh }) => {
  const [targets, setTargets] = useState({ ...targetsData });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [selectedInstitute, setSelectedInstitute] = useState(null);
  const [institutes, setInstitutes] = useState([]);

  const handleInstituteChange = (value) => {
    setSelectedInstitute(value);
  };

  const fetchInstitutes = async (email) => {
    const res = await DataService.post('/sales/getinstitutes', {
      email: email,
    }).catch((err) => {
      console.log(err);
      return err;
    });
    // console.log(res.data);

    setInstitutes(res.data.institutes.map((institute) => ({ value: institute, label: institute })));
  };

  const data = [
    {
      element: 'AGREEMENTED STUDENTS',
      thisMonth:
        targetsData?.agreementedTarget === 0
          ? 0
          : salesData['mtd']?.agreementedStudents / targetsData?.agreementedTarget,
      target: targetsData.agreementedTarget,
      achievement: salesData['mtd']?.agreementedStudents,
    },
    {
      element: 'Registrations',
      thisMonth:
        targetsData?.registrationsTarget === 0
          ? 0
          : salesData['mtd']?.studentOnboarded / targetsData?.registrationsTarget,
      target: targetsData.registrationsTarget,
      achievement: salesData['mtd']?.studentOnboarded,
    },
    {
      element: 'TEST COMPLETIONS',
      thisMonth: targetsData?.target === 0 ? 0 : salesData['mtd']?.testCompletions / targetsData?.target,
      target: targetsData.target,
      achievement: salesData['mtd']?.testCompletions,
    },
  ];

  const targetData = [];

  data.map((item, index) => {
    let achievementPercentage = Math.round(item.thisMonth * 100);
    const color =
      achievementPercentage >= 100
        ? 'success'
        : 100 > achievementPercentage && achievementPercentage > 70
        ? 'amber-500'
        : 'danger';

    return targetData.push({
      key: index,
      elements: <span className={`text-${color}`}>{item.element}</span>,
      thisMonth: (
        <div className={`flex items-center gap-[14px]`}>
          <span className={`font-semibold capitalize text-[14px] text-${color}`}>{achievementPercentage}%</span>
        </div>
      ),
      target: <span className="font-medium capitalize text-[14px] text-dark dark:text-white87">{item.target}</span>,
      achievement: (
        <span className="font-medium capitalize text-[14px] text-dark dark:text-white87">{item.achievement}</span>
      ),
    });
  });

  const targetColumns = [
    {
      title: 'Elements',
      dataIndex: 'elements',
      key: 'elements',
      className:
        'ltr:pr-4 rtl:pl-4 font-semibold dark:text-white60 text-[12px] py-2.5 last:text-start uppercase before:hidden',
    },
    {
      title: 'MTD achievement %',
      dataIndex: 'thisMonth',
      key: 'thisMonth',
      className: ``,
    },
    {
      title: 'MTD achievement',
      dataIndex: 'achievement',
      key: 'achievement',
      className: ` `,
    },
    {
      title: 'Month Target',
      dataIndex: 'target',
      key: 'target',
      className:
        'ltr:pr-4 rtl:pl-4 font-semibold dark:text-white60 text-facebook text-[12px] py-2.5 last:text-start uppercase before:hidden',
    },
  ];

  const handleTarget = async () => {
    form.validateFields().then(async (values) => {
      const monthYear = new Date()
        .toLocaleDateString('en-US', {
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '-');

      const res = await DataService.post('/sales/settarget', {
        email: email,
        monthYear: monthYear,
        target: targets,
      }).catch((err) => {
        console.log(err);
        notification['error']({
          message: JSON.stringify(err),
        });
        return;
      });

      refresh();

      notification['success']({
        message: res.data.message,
      });

      setIsModalVisible(false);
    });
  };

  useEffect(() => {
    fetchInstitutes(email);
  }, []);

  return (
    <>
      <div className="w-full h-full bg-white dark:bg-whiteDark rounded-[10px] mb-3">
        <GlobalUtilityStyle>
          <Cards
            title={email}
            size="medium"
            className="text-green-500"
            isbutton={
              <>
                <div className="flex space-x-4">
                  <Select
                    className="text-sm"
                    placeholder="Institute"
                    style={{ width: 150 }}
                    value={selectedInstitute}
                    onChange={handleInstituteChange}
                    options={institutes}
                  ></Select>
                </div>
                <div className="flex flex-row mx-2 ">
                  <Link
                    className="flex p-2 text-sm font-semibold uppercase rounded-xl text-info bg-info-transparent hover:text-blue-600"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Set Target
                  </Link>
                </div>
                <div className="flex flex-row mx-2 ">
                  <Link
                    className="flex p-2 text-sm font-semibold uppercase rounded-xl text-info bg-info-transparent hover:text-blue-600"
                    onClick={() => {
                      window.location.href = `/admin/pages/sales?user=${email}`;
                    }}
                  >
                    View Details
                  </Link>
                </div>
              </>
            }
          >
            <TableDefaultStyle>
              <div className="">
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active />
                    </Cards>
                  }
                >
                  <Table columns={targetColumns} dataSource={targetData} pagination={false} size="small" />
                </Suspense>
              </div>
            </TableDefaultStyle>
          </Cards>
        </GlobalUtilityStyle>

        <Modal
          title="Set Targets"
          open={isModalVisible}
          onOk={() => handleTarget()}
          onCancel={() => setIsModalVisible(false)}
        >
          <Form form={form}>
            <Form.Item
              label="TEST COMPLETIONS #"
              name="target"
              rules={[{ required: true, message: 'Please enter target' }]}
            >
              <InputNumber
                min={0}
                value={targets.target}
                onChange={(value) => setTargets({ ...targets, target: value })}
              />
            </Form.Item>
            <Form.Item
              label="AGREEMENTED # OF STUDENTS"
              name="agreementedTarget"
              rules={[{ required: true, message: 'Please enter target' }]}
            >
              <InputNumber
                min={0}
                value={targets.agreementedTarget}
                onChange={(value) => setTargets({ ...targets, agreementedTarget: value })}
              />
            </Form.Item>
            <Form.Item
              label="Registrations #"
              name="registrationsTarget"
              rules={[{ required: true, message: 'Please enter target' }]}
            >
              <InputNumber
                min={0}
                value={targets.registrationsTarget}
                onChange={(value) => setTargets({ ...targets, registrationsTarget: value })}
              />
            </Form.Item>
            <p className="mt-2">Once targets set cant be changed</p>
          </Form>
        </Modal>
      </div>
    </>
  );
});

export default SalesOverviewAdmin;
