import React, { Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton, Progress } from 'antd';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import SalesOverviewAdmin from './SalesOverview_admin';
import { DataService } from '../../../config/dataService/dataService';
import { getItem } from '../../../utility/localStorageControl';
import salesteam from '../../../AcadSpace_Sales/salesteam.json';

function SalesAdmin() {
  const [state, setState] = useState({ salesData: {}, targets: {} });
  const [salesTeamData, setSalesTeamData] = useState([]);
  const [reload, setreload] = useState(false);

  const refresh = () => {
    console.log('called refresh');
    setreload(!reload);
    setSalesTeamData([]);
  };

  useEffect(() => {
    for (const email of salesteam) {
      // console.log(email);
      fetchSalesData(email);
    }
    // console.log(salesTeamData);
  }, [reload]);

  const fetchSalesData = async (email) => {
    const res = await DataService.post('/sales/getsales', {
      email: email,
    }).catch((err) => {
      console.log(err);
      return err;
    });

    setState({
      salesData: res.data.achievements,
      targets: {
        target: res.data.target,
        agreementedTarget: res.data.agreementedTarget,
        registrationsTarget: res.data.registrationsTarget,
      },
    });

    setSalesTeamData((prev) => [
      ...prev,
      {
        email: email,
        salesData: res.data.achievements,
        targets: {
          target: res.data.target,
          agreementedTarget: res.data.agreementedTarget,
          registrationsTarget: res.data.registrationsTarget,
        },
      },
    ]);

    // console.log(res.data.achievements)
  };

  return (
    <>
      <PageHeader
        title="Sales Master Dashboard"
        className="flex justify-between items-center px-8 xl:px-3 pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12 font-mono">
        <Row gutter={25}>
          <Col sm={24} xs={24}>
            <div x="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                {salesTeamData.map((teamMember, index) => {
                  // console.log(teamMember);
                  return (
                    <div id="index">
                      <SalesOverviewAdmin
                        salesData={teamMember.salesData}
                        targetsData={teamMember.targets}
                        setState={setState}
                        state={state}
                        email={teamMember.email}
                        refresh={refresh}
                      />
                    </div>
                  );
                })}
              </Suspense>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default SalesAdmin;
