import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { GlobalUtilityStyle } from '../../styled';
import { DataService } from '../../../config/dataService/dataService';

const OverviewDataList = lazy(() => import('./overview/index/OverviewDataList'));
const SalesReport = lazy(() => import('./overview/index/SalesReport'));

function SalesDashboard() {
  const PageRoutes = [
    {
      path: 'index',
      breadcrumbName: 'Dashboard',
    },
    {
      path: 'first',
      breadcrumbName: 'Home',
    },
  ];

  const [salesOverview, setSalesOverview] = useState({});

  useEffect(() => {
    async function fetchData() {
      const res = await DataService.post(`/sales/getSalesOverview`, {});

      setSalesOverview(res?.data);
    }
    fetchData();
  }, []);

  return (
    <>
      <PageHeader
        routes={PageRoutes}
        title="Dashboard"
        className="flex items-center justify-between px-8 xl:px-3 pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <GlobalUtilityStyle>
        <div className="min-h-[715px] lg:min-h-[580px] flex-1 h-auto px-8 xl:px-3 pb-[30px] bg-transparent">
          <Row gutter={25}>
            <Col xxl={12} xs={24}>
              <Suspense
                fallback={
                  <Cards headless className="mb-[25px]">
                    <Skeleton active />
                  </Cards>
                }
              >
                <OverviewDataList salesOverview={salesOverview?.salesOverview} />
              </Suspense>
            </Col>
            <Col xxl={12} xs={24} className="mb-[25px]">
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <SalesReport salesReport={salesOverview?.salesReport} />
              </Suspense>
            </Col>
          </Row>
        </div>
      </GlobalUtilityStyle>
    </>
  );
}

export default SalesDashboard;
