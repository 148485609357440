import React, { lazy, useState, useEffect, Suspense } from 'react';
import { Row, Col, Spin, Skeleton } from 'antd';
import { Link, Route, Routes } from 'react-router-dom';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import UilAlignLeft from '@iconscout/react-unicons/icons/uil-align-left';
import { PageHeader } from '../../../components/page-headers/page-headers';

import { Button } from '../../../components/buttons/buttons';
import { DataService } from '../../../config/dataService/dataService';

const Filters = lazy(() => import('./overview/Filters'));
const Grid = lazy(() => import('./overview/Grid'));
const List = lazy(() => import('./overview/List'));

function Webinars() {
  const PageRoutes = [
    {
      path: '/admin',
      breadcrumbName: 'Dashboard',
    },
    {
      path: '',
      breadcrumbName: 'Webinars',
    },
  ];

  const [isDivVisible, setIsDivVisible] = useState(false);
  const [webinars, setwebinars] = useState([]);
  const [filter, setFilter] = useState({});

  const func = async (filter) => {
    const res = await DataService.post('admin/getallwebiners', { filter }).catch((err) => {
      console.log(err);
    });

    console.log('Got webinars : ', res?.data?.webiners);

    setwebinars(res?.data?.webiners);
  };

  useEffect(() => {
    func(filter);

    const mq = window.matchMedia('(min-width: 1200px)');

    function handleMqChange(event) {
      setIsDivVisible(event.matches);
    }

    mq.addEventListener('change', handleMqChange);
    setIsDivVisible(mq.matches);

    return () => {
      mq.removeEventListener('change', handleMqChange);
    };
  }, []);

  useEffect(() => {
    console.log(filter);
    func(filter);
  }, [filter]);

  function handleButtonClick() {
    setIsDivVisible(!isDivVisible);
    const div = document.querySelector('.ant-layout-content');
    div.classList.toggle('overlay');
  }

  function handleClose() {
    setIsDivVisible(false);
    const div = document.querySelector('.ant-layout-content');
    div.classList.remove('overlay');
  }

  return (
    <>
      <div className="flex justify-between w-full px-8 pt-6 xl:px-3 pb-8 sm:pb-[30px]">
        <div className="flex items-center justify-between text-2xl font-semibold text-black bg-transparent sm:flex-col">
          Webinars
        </div>
        <div>
          <Link
            className="px-[14px] text-[14px] font-semibold text-sm rounded-md h-[40px] bg-primary text-white flex items-center justify-center gap-[5px]"
            to={{
              pathname: '/admin/activities/add-webinar',
            }}
          >
            Add New Webinar
          </Link>
        </div>
      </div>
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 xl:px-3 pb-[50px] ssm:pb-[30px]">
        <Row gutter={30}>
          <Col xxl={5} xl={6} md={12} xs={20}>
            <button
              className="mb-3 w-[45px] h-[45px] hidden xl:inline-flex items-center justify-center p-0 text-primary bg-white dark:bg-white10 dark:text-white87 border-1 border-white dark:border-white10 rounded-6"
              type="button"
              onClick={handleButtonClick}
            >
              <UilAlignLeft />
            </button>
            {isDivVisible || window.innerWidth >= 1200 ? (
              <div className="xl:absolute xl:top-[30px] ltr:xl:left-3 rtl:xl:right-3 xl:w-full xl:h-full content-[''] xl:z-[2]">
                <Button
                  type="link"
                  className="hidden bg-transparent border-none text-danger sm:px-0 xl:inline-flex ltr:float-right rtl:float-left z-[1] mt-[14px] ltr:sm:mr-3 rtl:sm:ml-3"
                  style={{ marginTop: 0 }}
                  onClick={handleClose}
                >
                  <UilTimes />
                </Button>
                <Suspense
                  fallback={
                    <div className="bg-white dark:bg-white10 p-[25px] rounded-[10px]">
                      <Skeleton paragraph={{ rows: 22 }} active />
                    </div>
                  }
                >
                  <Filters setFilter={setFilter} filter={filter} />
                </Suspense>
              </div>
            ) : null}
          </Col>

          <Col xxl={19} xl={17} md={24} xs={24}>
            <Suspense
              fallback={
                <div className="spin flex items-center justify-center h-[calc(100vh-132px)]">
                  <Spin />
                </div>
              }
            >
              <Routes>
                <Route index element={<Grid webinars={webinars} />} />
                <Route path="grid" element={<Grid webinars={webinars} />} />
                <Route path="list" element={<List />} />
              </Routes>
            </Suspense>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default Webinars;
