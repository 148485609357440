import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import withAdminLayout from '../../layout/withAdminLayout';
import { getItem } from '../../utility/localStorageControl';
import Sales from '../../container/pages/sales/Sales';
import SalesAdmin from '../../container/pages/sales_admin/SalesAdmin';
import SalesDashboard from '../../container/pages/sales_dashboard/SalesDashboard';
import Webinars from '../../container/activities/webinar/Webinars';
const ActivationPage = lazy(() => import('../../container/pages/activation/activation'));
const Pages = lazy(() => import('./pages'));
const Users = lazy(() => import('./users'));
const Form = lazy(() => import('./forms'));
const Activities = lazy(() => import('./activities.js'));

const NotFound = lazy(() => import('../../container/pages/404'));

const Admin = React.memo(() => {
  const { pathname } = useLocation();
  const role = getItem('role');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
          <Spin />
        </div>
      }
    >
      <Routes>
        {role === 'ROLE_ADMIN' && <Route index path="/*" element={<ActivationPage />} />}
        {role === 'ROLE_SALES' && <Route index path="/*" element={<Sales />} />}
        {role === 'ROLE_SALES_ADMIN' && <Route index path="/*" element={<SalesDashboard />} />}
        {role === 'ROLE_CLIENT' && <Route index path="/*" element={<Webinars />} />}
        <Route path="pages/*" element={<Pages />} />
        <Route path="users/*" element={<Users />} />
        <Route path="forms/*" element={<Form />} />
        <Route path="activities/*" element={<Activities />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);
