import { Progress, Table } from 'antd';
import React from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { GlobalUtilityStyle, TableDefaultStyle } from '../../styled';

const timePeriod = ['today', 'wtd', 'mtd', 'lastweek', 'lastmonth'];

const locationColumns = [
  {
    title: 'Time Period',
    dataIndex: 'duration',
    key: 'duration',
    className:
      'ltr:pr-4 rtl:pl-4 text-light dark:text-white60 text-[12px] py-2.5 last:text-start  uppercase before:hidden',
  },
  {
    title: 'Agreemented # of students',
    dataIndex: 'agreementedStudents',
    key: 'agreementedStudents',
    className:
      'ltr:pr-4 rtl:pl-4 text-light dark:text-white60 text-[12px] py-2.5 last:text-start  uppercase before:hidden',
  },
  {
    title: 'Registrations #',
    dataIndex: 'studentOnboarded',
    key: 'studentOnboarded',
    className:
      'ltr:pr-4 rtl:pl-4 text-light dark:text-white60 text-[12px] py-2.5 last:text-start  uppercase before:hidden',
  },
  {
    title: ' Test completions #',
    dataIndex: 'testCompletions',
    key: 'testCompletions',
    className:
      'ltr:pr-4 rtl:pl-4 text-light dark:text-white60 text-[12px] py-2.5 last:text-start  uppercase before:hidden',
  },
  {
    title: '% Achievement',
    dataIndex: 'achievementPercentage',
    key: 'achievementPercentage',
    className:
      'ltr:pr-4 rtl:pl-4 text-light dark:text-white60 text-[12px] py-2.5 last:text-start uppercase before:hidden ltr:rounded-r-4 rtl:rounded-l-4',
  },
];

const SalesTable = React.memo(({ salesData }) => {
  const revenueData = [];

  timePeriod.map((period, index) => {
    let dataForPeriod = salesData[period];
    // console.log('period', dataForPeriod, period);

    if (dataForPeriod) {
      const { icon, studentOnboarded, testCompletions, achievementPercentage, agreementedStudents } = salesData[period];
      return revenueData.push({
        key: index,
        duration: <span className="font-medium capitalize text-[14px] text-dark dark:text-white87">{period}</span>,
        studentOnboarded: (
          <span className="font-medium capitalize text-[14px] text-dark dark:text-white87">{studentOnboarded}</span>
        ),
        agreementedStudents: (
          <span className="font-medium capitalize text-[14px] text-dark dark:text-white87">{agreementedStudents}</span>
        ),
        testCompletions: (
          <span className="font-normal capitalize text-[14px] text-dark dark:text-white87">{testCompletions}</span>
        ),
        achievementPercentage: (
          <div className={`flex items-center gap-[14px] text-${icon}`}>
            {achievementPercentage >= 75 ? (
              <Progress
                percent={achievementPercentage}
                strokeWidth={4}
                status="active"
                strokeColor={'#01B81A'}
                className="min-w-[60px]"
              />
            ) : achievementPercentage >= 50 ? (
              <Progress
                percent={achievementPercentage}
                strokeWidth={4}
                status="active"
                strokeColor={'#FACC14'}
                className="min-w-[60px] [&>.ant-progress-outer>.ant-progress-inner>.ant-progress-bg]:bg-yellow-400"
              />
            ) : (
              <Progress
                percent={achievementPercentage}
                strokeWidth={4}
                status="active"
                strokeColor={'#EF4444'}
                className="min-w-[60px] [&>.ant-progress-outer>.ant-progress-inner>.ant-progress-bg]:bg-red-500"
              />
            )}
          </div>
        ),
      });
    } else {
      return {};
    }
  });

  return (
    <div className="full-width-table h-full bg-white dark:bg-whiteDark rounded-[10px]">
      <GlobalUtilityStyle>
        <Cards
          // isbutton={
          //   <div className="flex space-x-4">
          //     <Select placeholder="Institute" style={{ width: 120 }}>
          //       <Option value="school">School</Option>
          //       <Option value="college">College</Option>
          //       {/* Add more options as needed */}
          //     </Select>

          //     <Select placeholder="Class" style={{ width: 120 }}>
          //       <Option value="class8">Class 8</Option>
          //       <Option value="class9">Class 9</Option>
          //       {/* Add more options as needed */}
          //     </Select>

          //     <Select placeholder="City" style={{ width: 120 }}>
          //       <Option value="kolkata">Kolkata</Option>
          //       <Option value="delhi">Delhi</Option>
          //       {/* Add more options as needed */}
          //     </Select>
          //   </div>
          // }
          title="Your Achievements"
          size="large"
          className="border-none ant-card-body-p-25 ant-card-body-pt-0 ant-card-head-px-25 ant-card-head-b-none ant-card-head-title-base"
        >
          <TableDefaultStyle>
            <div className=" table-pt-15 table-responsive ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-l-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-r-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-none ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-r-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-l-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-none">
              <Table columns={locationColumns} dataSource={revenueData} pagination={false} />
            </div>
          </TableDefaultStyle>
        </Cards>
      </GlobalUtilityStyle>
    </div>
  );
});

export default SalesTable;
