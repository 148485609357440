import Cookies from 'js-cookie';
import actions from './actions';
import { DataService } from '../../config/dataService/dataService';
import { alertModal } from '../../components/modals/antd-modals';
import { setItem } from '../../utility/localStorageControl';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr } = actions;

const login = (values, callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/auth/signin', values);

      if (response.status !== 200) {
        alertModal.error({
          title: 'Login failed',
          content: response.data.message,
        });
        dispatch(loginErr(response.data.message));
      } else {
        if (response.data.roles.includes('ROLE_ADMIN') || response.data.roles.includes('ROLE_CLIENT')) {
          console.log('login success : ', response.data);
          Cookies.set('access_token', response.data.token);
          Cookies.set('logedIn', true);
          Cookies.set('user', response.data);
          dispatch(loginSuccess(true));
          setItem('role', response.data.roles[0]);
          callback();
        } else {
          alertModal.error({
            title: 'You are not an administrator',
            content: response.data.message,
          });
          dispatch(loginErr('You are not an administrator'));
        }
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post('/register', values);
      if (response.data.errors) {
        dispatch(loginErr('Registration failed!'));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      dispatch(logoutSuccess(false));
      callback();
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { login, logOut, register };
