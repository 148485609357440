import React, { Suspense, useEffect, useState } from 'react';
import { Row, Col, Skeleton, Progress } from 'antd';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Cards } from '../../../components/cards/frame/cards-frame';
import SalesTable from './SalesTable';
import SalesOverview from './SalesOverview';
import { DataService } from '../../../config/dataService/dataService';
import { getItem } from '../../../utility/localStorageControl';

function Sales() {
  const [state, setState] = useState({ salesData: {}, targets: {} });
  const [showMessage, setShowMessage] = useState(false);
  // console.log(state);
  const achievementPercentage =
    state?.targets?.target === 0
      ? 0
      : Math.round((state?.salesData['mtd']?.testCompletions / state?.targets?.target) * 100);
  const color =
    achievementPercentage >= 100
      ? 'success'
      : 100 > achievementPercentage && achievementPercentage > 70
      ? 'orange-500'
      : 'danger';

  // console.log(achievementPercentage, color);

  let user = getItem('user');
  // console.log(user.email);

  useEffect(() => {
    fetchSalesData();
    // Function to check if the current date is within the last 10 days of the month
    const isWithinLast10Days = () => {
      const currentDate = new Date();
      const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      const daysRemaining = lastDayOfMonth.getDate() - currentDate.getDate() + 1;

      return daysRemaining <= 10;
    };

    // Set the state based on the condition
    setShowMessage(isWithinLast10Days());
  }, []); // Empty dependency array to run the effect only once on mount

  const fetchSalesData = async () => {
    const res = await DataService.post('/sales/getsales', {
      email: user.email,
    }).catch((err) => {
      console.log(err);
      return err;
    });

    setState({
      salesData: res.data.achievements,
      targets: {
        target: res.data.target,
        agreementedTarget: res.data.agreementedTarget,
        registrationsTarget: res.data.registrationsTarget,
      },
    });

    // console.log(res.data.achievements)
  };

  return (
    <>
      <PageHeader
        title="Sales Dashboard"
        className="flex justify-between items-center px-8 xl:px-3 pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
      />
      <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 pb-12">
        <Row gutter={25}>
          {showMessage && (
            <Col sm={24} xs={24}>
              <div
                className={`flex flex-col justify-center items-center bg-white [&>div>div>.ant-progress-text]:text-${color} [&>div>div>.ant-progress-text]:font-semibold dark:bg-white10 m-0 mb-[25px] rounded-10 relative p-4`}
              >
                <Progress
                  type="circle"
                  width={130}
                  percent={achievementPercentage}
                  strokeColor="#8231D3"
                  trailColor={'#E6D5F6'}
                />
                <div className="mt-4">
                  {achievementPercentage <= 70 && (
                    <p className={`text-lg font-semibold text-${color}`}>
                      You are way below your month's target! Please achieve minimum requirement to avoid PIP!
                    </p>
                  )}
                  {100 > achievementPercentage && achievementPercentage > 70 && (
                    <p className={`text-lg font-semibold text-${color}`}>
                      You're almost there! Give it a harder push & bag that monthly incentive home!
                    </p>
                  )}
                  {achievementPercentage >= 100 && (
                    <p className={`text-lg font-semibold text-${color}`}>
                      Congratulations! You're doing a great job! Keep at it and earn more incentives!
                    </p>
                  )}
                </div>
              </div>
            </Col>
          )}
          <Col sm={24} xs={24}>
            <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <SalesTable salesData={state.salesData} />
              </Suspense>
            </div>
          </Col>
          <Col sm={24} xs={24}>
            <div x="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <SalesOverview
                  salesData={state.salesData}
                  targetsData={state.targets}
                  setState={setState}
                  state={state}
                />
              </Suspense>
            </div>
          </Col>
        </Row>
      </main>
    </>
  );
}

export default Sales;
