import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form, Input, Button, InputNumber } from 'antd';

import { AuthFormWrap } from './style';

function SignUpPartner() {

  const [state, setState] = useState({
    values: null,
    checked: null,
  });

  const history = useNavigate();

  const handleSubmit = async (values) => {
    console.log(values);
    // const res = await DataService.post(`/auth/clientsignup`, values);
    // console.log(res);
    // if (res.status === 200) {
    //   notification['success']({
    //     message: 'Client added successfully',
    //     description: res.data.message,
    //   });
    //   history('/admin/forms');
    // }

    window.location.href = 'https://app.acadspace.org/';
  };

  const onChange = (checked) => {
    setState({ ...state, checked });
  };

  return (
    <Row justify="center">
      <Col xxl={6} xl={8} md={12} sm={18} xs={24}>
        <AuthFormWrap className="mt-6 bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
          <div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
            <h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">Partner Sign Up AcadSpace</h2>
          </div>
          <div className="px-10 pt-8 pb-6">
            <Form name="register" onFinish={handleSubmit} layout="vertical">
              <Form.Item
                label="Name"
                name="name"
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                rules={[{ required: true, message: 'Please input your Full name!' }]}
              >
                <Input placeholder="Full name" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email Address"
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
              >
                <Input placeholder="name@example.com" />
              </Form.Item>
              <Form.Item
                name="city"
                label="City"
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                rules={[{ required: true, message: 'Please input your City' }]}
              >
                <Input placeholder="Enter City" />
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="mobileNo"
                className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
                rules={[
                  {
                    required: true,
                    type: 'number',
                    min: 1000000000,
                    max: 9999999999,
                    message: 'Please enter a valid 10-digit mobile number!',
                  },
                ]}
              >
                <InputNumber placeholder="Enter Mobile Number" style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item>
                <Button
                  className="w-full h-12 p-0 my-6 text-sm font-medium"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  Create Account
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="p-6 text-center bg-gray-100 dark:bg-white10 rounded-b-md">
            <p className="mb-0 text-sm font-medium text-body dark:text-white60">
              Already have an account?
              <Link to="/" className="ltr:ml-1.5 rtl:mr-1.5 text-info hover:text-primary">
                Sign In
              </Link>
            </p>
          </div>
        </AuthFormWrap>
      </Col>
    </Row>
  );
}

export default SignUpPartner;
