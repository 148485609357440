import { Form, InputNumber, Modal, Skeleton, Table, notification } from 'antd';
import React, { Suspense, useState } from 'react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { GlobalUtilityStyle, TableDefaultStyle } from '../../styled';

import { Link } from 'react-router-dom';
import { DataService } from '../../../config/dataService/dataService';
import { getItem } from '../../../utility/localStorageControl';

const SalesOverview = React.memo(({ salesData, targetsData, setState, state }) => {
  const [targets, setTargets] = useState({ ...targetsData });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  let user = getItem('user');
  // console.log(user.email);

  // useEffect(() => {
  //   setTargets({ ...targetsData });
  //   console.log(targetsData, targets);
  // }, []);

  const data = [
    {
      element: 'AGREEMENTED STUDENTS',
      thisMonth:
        targetsData?.agreementedTarget === 0
          ? 0
          : salesData['mtd']?.agreementedStudents / targetsData?.agreementedTarget,
      target: targetsData.agreementedTarget,
    },
    {
      element: 'Registrations',
      thisMonth:
        targetsData?.registrationsTarget === 0
          ? 0
          : salesData['mtd']?.studentOnboarded / targetsData?.registrationsTarget,
      target: targetsData.registrationsTarget,
    },
    {
      element: 'TEST COMPLETIONS',
      thisMonth: targetsData?.target === 0 ? 0 : salesData['mtd']?.testCompletions / targetsData?.target,
      target: targetsData.target,
    },
  ];

  const targetData = [];

  data.map((item, index) => {
    let achievementPercentage = Math.round(item.thisMonth * 100);
    const color =
      achievementPercentage >= 100
        ? 'success'
        : 100 > achievementPercentage && achievementPercentage > 70
        ? 'orange-500'
        : 'danger';

    return targetData.push({
      key: index,
      elements: <span className={` text-[14px] text-${color}`}>{item.element}</span>,
      thisMonth: (
        <div className={`flex items-center gap-[14px]`}>
          <span className={`font-semibold capitalize text-[14px] text-${color}`}>{achievementPercentage}%</span>
        </div>
      ),
      target: <span className="font-medium capitalize text-[14px] text-dark dark:text-white87">{item.target}</span>,
      action: (
        <div className="flex flex-row min-w-[150px] -m-2 ">
          {item.target !== 0 ? (
            <Link className="flex p-1 m-1 text-base text-gray-500 uppercase rounded-3xl">Set Target</Link>
          ) : (
            <Link
              className="flex p-1 m-1 text-base uppercase rounded-3xl text-info hover:text-blue-600"
              onClick={() => setIsModalVisible(true)}
            >
              Set Target
            </Link>
          )}
        </div>
      ),
    });
  });

  const targetColumns = [
    {
      title: 'Elements',
      dataIndex: 'elements',
      key: 'elements',
      className:
        'ltr:pr-4 rtl:pl-4 font-semibold dark:text-white60 text-[12px] py-2.5 last:text-start uppercase before:hidden',
    },
    {
      title: 'MTD achievement',
      dataIndex: 'thisMonth',
      key: 'thisMonth',
      className: ``,
    },
    {
      title: 'Month Target',
      dataIndex: 'target',
      key: 'target',
      className:
        'ltr:pr-4 rtl:pl-4 text-light dark:text-white60 text-[12px] py-2.5 last:text-start uppercase before:hidden',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className:
        'ltr:pr-4 rtl:pl-4 text-light dark:text-white60 text-[12px] py-2.5 last:text-start uppercase before:hidden',
    },
  ];

  const handleTarget = async () => {
    form.validateFields().then(async (values) => {
      const monthYear = new Date()
        .toLocaleDateString('en-US', {
          month: '2-digit',
          year: 'numeric',
        })
        .replace(/\//g, '-');

      const res = await DataService.post('/sales/settarget', {
        email: user.email,
        monthYear: monthYear,
        target: targets,
      }).catch((err) => {
        console.log(err);
        notification['error']({
          message: JSON.stringify(err),
        });
        return;
      });

      setState({ ...state, targets });

      notification['success']({
        message: res.data.message,
      });

      setIsModalVisible(false);
    });
  };

  return (
    <>
      <div className="w-full h-full bg-white dark:bg-whiteDark rounded-[10px]">
        <GlobalUtilityStyle>
          <Cards
            title="Your target"
            size="large"
            className="ant-card-body-p-25 ant-card-body-pt-0 ant-card-head-px-25 ant-card-head-b-none ant-card-head-title-base"
          >
            <TableDefaultStyle>
              <div className="hover-tr-none table-pt-15 table-responsive ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-l-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-r-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-none ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-r-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-l-4 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-none">
                <Suspense
                  fallback={
                    <Cards headless>
                      <Skeleton active />
                    </Cards>
                  }
                >
                  <Table columns={targetColumns} dataSource={targetData} pagination={false} />
                </Suspense>
              </div>
            </TableDefaultStyle>
          </Cards>
        </GlobalUtilityStyle>
        <Modal
          title="Set Targets"
          open={isModalVisible}
          onOk={() => handleTarget()}
          onCancel={() => setIsModalVisible(false)}
        >
          <Form form={form}>
            <Form.Item
              label="TEST COMPLETIONS #"
              name="target"
              rules={[{ required: true, message: 'Please enter target' }]}
            >
              <InputNumber
                min={0}
                value={targets.target}
                onChange={(value) => setTargets({ ...targets, target: value })}
              />
            </Form.Item>
            <Form.Item
              label="AGREEMENTED # OF STUDENTS"
              name="agreementedTarget"
              rules={[{ required: true, message: 'Please enter target' }]}
            >
              <InputNumber
                min={0}
                value={targets.agreementedTarget}
                onChange={(value) => setTargets({ ...targets, agreementedTarget: value })}
              />
            </Form.Item>
            <Form.Item
              label="Registrations #"
              name="registrationsTarget"
              rules={[{ required: true, message: 'Please enter target' }]}
            >
              <InputNumber
                min={0}
                value={targets.registrationsTarget}
                onChange={(value) => setTargets({ ...targets, registrationsTarget: value })}
              />
            </Form.Item>
            <p className="mt-2">Once targets set cant be changed</p>
          </Form>
        </Modal>
      </div>
    </>
  );
});

export default SalesOverview;
